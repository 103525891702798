*{
  
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#logo{
  height:220px;
  width: 170px;
  margin-top: 100px;
}
#search-logo{
  height: 60px;
}
body{
  background-color: #282c34;
}
#input{
  color: white;
  border-color: white;
  
}
#login-logo{
  height: 80px;
}
a {
  color: #3f51b5;
  font-weight: bolder;
  cursor: pointer;
}
.font{
  color: white;
}
#welcome-text{
  color: white;
  display: flex;
  
}
#salesman:hover{
  color: #3f51b5;
  opacity: .5;
  cursor: pointer;
}

ul {
  list-style: none;
}

